import React from "react";
import Image from "next/image";
import Link from "next/link";

import useCheckMobileScreen from "../../lib/IsMobile";

import useCheckIfLangIsPolish from "../../lib/IsPolish";

import { urlFor } from "../../lib/Client";

import { FiUsers, FiMapPin, FiClock } from "react-icons/fi";

import styles from "./TourCard.module.scss";

const TourCard = ({ data }) => {
    const isPolish = useCheckIfLangIsPolish();
    // const minPrice = data.numberOfPeopleCombos
    //     ? data.numberOfPeopleCombos[0].Price
    //     : "Contact Us";
    const minPeople = data.numberOfPeopleCombos
        ? data.numberOfPeopleCombos[0].People
        : "Contact us";

    const isMobile = useCheckMobileScreen();

    const pricePerPerson = round(
        data.numberOfPeopleCombos.slice(-1)[0].Price /
            data.numberOfPeopleCombos.slice(-1)[0].People,
        0
    );

    function round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    return (
        <Link
            href={
                !isPolish
                    ? data.isEvent
                        ? `/events/${data.slug.current}`
                        : `/tours/${data.slug.current}`
                    : data.isEvent
                    ? `/pl/eventy/${data.slug.current}`
                    : `/pl/wycieczki/${data.slug.current}`
            }
            className={styles.tourCard}
        >
            <div>
                <div className={styles.image}>
                    {data.groupTour && (
                        <div className={styles.badge}>
                            {isPolish ? "Dla grup" : "Great for groups"}
                        </div>
                    )}
                    <img
                        src={
                            !isMobile
                                ? urlFor(data.poster).width(273).height(200)
                                : urlFor(data.poster).width(250).height(133)
                        }
                        alt={data.poster.alt}
                    />
                </div>
                <div className={styles.data}>
                    <div className={styles.text}>
                        <div>
                            <h3>{data.title}</h3>
                            <p className={styles.subtitle}>{data.subtitle}</p>
                        </div>
                        <div className={styles.price}>
                            <p className={styles.small}>
                                {isPolish ? "od" : "from"}
                            </p>
                            <p>
                                {isPolish ? null : "€"}
                                {pricePerPerson}
                                {isPolish ? "zł" : null}
                            </p>
                        </div>
                    </div>
                    <div className={styles.details}>
                        <div className={styles.detail}>
                            <p>
                                <FiUsers className={styles.icon} />
                                {isPolish ? "Min liczba osób" : "Min People"}
                            </p>
                            <p>{minPeople}</p>
                        </div>
                        <div className={styles.detail}>
                            <p>
                                <FiMapPin className={styles.icon} />
                                {isPolish ? "Miejsce" : "Place"}
                            </p>
                            <p>{data.location}</p>
                        </div>
                        <div className={styles.detail}>
                            <p>
                                <FiClock className={styles.icon} />
                                {isPolish ? "Czas trwania" : "Duration"}
                            </p>
                            <p>{data.duration}h</p>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default TourCard;
