import React from "react";
import Link from "next/link";
import { urlFor } from "../../lib/Client";

import styles from "./PostCard.module.scss";

import useCheckIfLangIsPolish from "../../lib/IsPolish";

const PostCard = ({ data }) => {
    const isPolish = useCheckIfLangIsPolish();
    var newDateOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    };
    return (
        <>
            <Link
                href={
                    isPolish
                        ? `/pl/blog/${data.slug.current}`
                        : `/blog/${data.slug.current}`
                }
                className={styles.postCard}
            >
                <div className={styles.date}>
                    <p>
                        {new Date(data._createdAt).toLocaleString(
                            "en-GB",
                            newDateOptions
                        )}
                    </p>
                </div>
                <div className={styles.image}>
                    <img
                        src={urlFor(data.poster).width(300).height(200)}
                        alt={data.poster.alt}
                    />
                </div>
                <div className={styles.title}>
                    <h3>{data.title}</h3>
                    <p>{data.subtitle}</p>
                </div>
            </Link>
        </>
    );
};

export default PostCard;
