import { useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";

import axios from "axios";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import useCheckIfLangIsPolish from "../../lib/IsPolish";

import styles from "../../styles/Home.module.scss";

const ContactForm = () => {
    const isPolish = useCheckIfLangIsPolish();
    //form
    const {
        handleSubmit,
        formState: { errors },
        register,
        setValue,
        control,
        reset,
    } = useForm({
        mode: "onChange",
    });
    const [isSuccess, setSuccess] = useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha();
    const [response, setResponse] = useState(null);

    const handleQuestion = async (body) => {
        if (!executeRecaptcha) {
            return;
        }
        try {
            const token = await executeRecaptcha();
            if (!token) {
                setResponse({ message: "Failed to Send!!!", status: "Failed" });
                return;
            }

            const response = await axios.post("/api/send-message", {
                token,
                body,
            });
            if (response.status === 200) {
                setSuccess(true);
            }
            if (response.status === 400) {
                setResponse({
                    message: isPolish
                        ? "It seems that you're a robot. Contact us at contact@polandbylocals.com instead."
                        : "It seems that you're a robot. Contact us at contact@polandbylocals.com instead.",
                    status: "Failed",
                });
            }
        } catch (error) {
            console.log(error);

            setResponse({
                message: isPolish
                    ? "It seems that you're a robot. Contact us at contact@polandbylocals.com instead."
                    : "It seems that you're a robot. Contact us at contact@polandbylocals.com instead.",
                status: "Failed",
            });
        }
    };

    // useEffect(() => {

    // }, []);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = (msg, token) => {
        setIsSubmitting(true);
        handleQuestion(msg);
        setIsSubmitting(false);
        reset();
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label>{isPolish ? "Imię i nazwisko" : "Your name"}</label>
                    <input
                        name='name'
                        {...register("name", {
                            required: true,
                        })}
                    />
                    {errors.name && (
                        <p className={styles.error}>
                            {isPolish
                                ? "Imię i nazwisko jest wymagane"
                                : "Please enter your name"}
                        </p>
                    )}
                </div>
                <div>
                    <label>{isPolish ? "Twój email" : "Your email"}</label>
                    <input
                        type='email'
                        name='email'
                        {...register("email", {
                            required: true,
                            // pattern:
                            //     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        })}
                    />
                    {errors.email && (
                        <p className={styles.error}>
                            {isPolish
                                ? "Podaj poprawny email"
                                : "Please enter valid email address"}
                        </p>
                    )}
                </div>
                <div>
                    <label>
                        {isPolish
                            ? "Napis czego potrzebujesz"
                            : "Tell us what you need"}
                    </label>
                    <textarea
                        type='text'
                        placeholder={
                            isPolish ? "Twoja wiadomość..." : "Your message..."
                        }
                        // onKeyPress={commentEnterSubmit}
                        name='message'
                        {...register("message", {
                            required: true,
                        })}
                    />
                    {errors.message && (
                        <p className={styles.error}>
                            {isPolish
                                ? "Wiadomości nie może być pusta"
                                : "Message cannot be empty"}
                        </p>
                    )}
                </div>
                <input
                    className={styles.bookButton}
                    defaultValue={
                        !isPolish
                            ? isSuccess
                                ? "Submitted"
                                : "Submit"
                            : isSuccess
                            ? "Wysłane"
                            : "Wyślij"
                    }
                    type='Submit'
                    disabled={isSubmitting || isSuccess}
                />
            </form>
            {isSuccess && (
                <div className={styles.success}>
                    {isPolish
                        ? "Twoja wiadomość została wysłana :)"
                        : "Your message has been sent :)"}
                </div>
            )}
            <p className={styles.error}>{response?.message}</p>
        </>
    );
};
export default ContactForm;
