import React from "react";
import Image from "next/image";
import styles from "./Divider.module.scss";
import divider from "../../public/divider.png";

const Divider = () => {
    return (
        <div className={styles.divider}>
            <Image src={divider} alt='divider image' />
        </div>
    );
};

export default Divider;
