// import { useMemo } from "react";
// import { useLoadScript, GoogleMap, MarkerF } from "@react-google-maps/api";
import styles from "./Mapa.module.scss";
import Image from "next/image";

import map43 from "../../public/map-43.jpg";
import map169 from "../../public/map-169.jpg";

const Mapa = ({ isWide }) => {
    // const libraries = useMemo(() => ["places"], []);

    // const mapOptions = useMemo(
    //     () => ({
    //         disableDefaultUI: true,
    //         clickableIcons: true,
    //         scrollwheel: false,
    //     }),
    //     []
    // );

    // const { isLoaded } = useLoadScript({
    //     googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY,
    //     // libraries: libraries,
    // });

    // if (!isLoaded) {
    //     return <p>Loading...</p>;
    // }

    return isWide ? (
        <div className={isWide ? styles.widemap : styles.map}>
            {" "}
            {/* <GoogleMap
                options={mapOptions}
                zoom={zoom}
                center={location}
                mapTypeId={google.maps.MapTypeId.ROADMAP}
                mapContainerStyle={{ width: "100%", height: "100%" }}
            >
                <MarkerF position={location} />
            </GoogleMap> */}
            <a href='https://maps.app.goo.gl/Efx3tFCTWJDKwPEe7'>
                <Image src={map169} alt='poland by locals location' />
            </a>
        </div>
    ) : (
        <div className={isWide ? styles.widemap : styles.map}>
            <a href='https://maps.app.goo.gl/Efx3tFCTWJDKwPEe7'>
                <Image src={map43} alt='poland by locals location' />
            </a>{" "}
        </div>
    );
};

export default Mapa;
